import { v4 as uuidv4 } from "uuid";
import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import ImagePopupPreview from "../../features/previews/ImagePopupPreview";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import Interaction, { InteractionType } from "./Interaction";

export interface ImagePopup extends Interaction {
  title: string;
  assetId: string;
}

export function CreateImagePopup(titleKey: string, assetId?: string): ImagePopup {
  return {
    id: uuidv4(),
    name: "Image Popup",
    type: InteractionType.ImagePopup,
    title: titleKey,
    assetId: assetId ?? "",
  };
}

export function MigrateImagePopup(from: number, to: number, interaction: ImagePopup): ImagePopup {
  return interaction;
}

export const imagePopupInteraction = (): InteractionFactoryEntity => {
  const component = <ImagePopupPreview />;

  const create = (getKeys: (amount: number) => string[]): ImagePopup => {
    const keys = getKeys(1);
    return {
      id: uuidv4(),
      name: "Image Popup",
      type: InteractionType.ImagePopup,
      title: keys[0],
      assetId: "",
    };
  };

  const migrate = (from: number, to: number, interaction: Interaction): ImagePopup => {
    return interaction as ImagePopup;
  };

  const copy = (interaction: Interaction, getKeys: (amount: number) => string[], replaceKey: (from: string, to: string) => void): ImagePopup => {

    const key = getKeys(1)[0];
    replaceKey((interaction as ImagePopup).title, key);

    return {
      id: uuidv4(),
      name: "Image Popup",
      type: InteractionType.ImagePopup,
      title: key,
      assetId: (interaction as ImagePopup).assetId,
    }

  }

  const validate = (interaction: Interaction, getValue: (key: string) => string): string[] => {
    const converted = interaction as ImagePopup;
    const warnings: string[] = [];

    ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");
    ValidationHelper.ValidateAssetId(converted.assetId, warnings);

    return warnings;
  }

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {

    const converted = interaction as ImagePopup;
    const searchableStrings: string[] = [
      interaction.name,
      converted.title];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType,
    );
  };

  const filterType: string = "Image Popup";

  return {
    View: component,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};


