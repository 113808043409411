import { useSelector } from "react-redux";
import { selectFilteredSearchResults, selectQuery } from "../../app/slices/SearchResultsSlice";
import { useUpdateSearchResults } from "./AdvancedSearchList.hook";
import AdvancedSearchResultField from "./AdvancedSearchResultField";

export default function AdvancedSearchList() {
  const inputQuery: string = useSelector(selectQuery);

  useUpdateSearchResults();
  const searchResults = useSelector(selectFilteredSearchResults);

  return (
    <div>
      {searchResults && searchResults.map((result) => (
        <AdvancedSearchResultField
          key={result.objectId}
          matchingText={result.matchingText}
          query={inputQuery}
          objectId={result.objectId}
          interactionId={result.interactionId}
          takeName={result.takeName}
          interactionType={result.interactionType}
          profilePicture={result.profilePicture}
        />
      ))}
    </div>
  );
}
