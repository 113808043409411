import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import SetPropStatePreview from "../../features/previews/set_prop_state/SetPropStatePreview";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { Actor } from "../Actor";
import { IAnswer } from "../Quiz";
import Take from "../Take";
import Interaction, { InteractionType } from "./Interaction";

export interface ISetPropState extends Interaction {
  propId: string;
  propState: string;
  waitForCustomEvent: boolean;
}

export const setPropStateInteraction = (): InteractionFactoryEntity => {
  const view = <SetPropStatePreview />;

  const create = (getKeys: (amount: number) => string[]): ISetPropState => {
    return {
      id: GetNewGuid(),
      name: "Set Prop State",
      type: InteractionType.SetPropState,
      propId: "",
      propState: "",
      waitForCustomEvent: false,
      properties: [],
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): ISetPropState => {
    return interaction as ISetPropState;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): ISetPropState => {
    return {
      ...interaction,
      id: GetNewGuid(),
    } as ISetPropState;
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as ISetPropState;
    const warnings: string[] = [];

    if (!converted.propId) warnings.push("No prop selected");
    if (!converted.propState) warnings.push("No Propstate selected");

    return warnings;
  };

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {

    const converted = interaction as ISetPropState;
    const searchableStrings: string[] = [
      interaction.name,
      converted.propState];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType,
    );
  };

  const filterType: string = "SetPropState";

  return {
    View: view,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
