import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InteractionType } from "../../models/interactions/Interaction";
import { RootState } from "../Store";

interface SearchResultsState {
    query: string,
    searchResultsObjects: SearchResultData[],
    selectedResultId: string;
    activeFilter: string
}

export interface SearchResultData {
    objectId: string;
    matchingText: string;
    filterType: string;
    takeName?: string;
    interactionId?: string;
    interactionType?: InteractionType | null;
    profilePicture?: string;
}

const initialState: SearchResultsState = {
    query: "",
    searchResultsObjects: [],
    selectedResultId: "",
    activeFilter: "",
};

export const searchResultsSlice = createSlice({
    name: "searchResults",
    initialState,
    reducers: {
        setQuery: (state, action: PayloadAction<string>) => {
            state.query = action.payload;
        },
        setSelectedResultId: (state, action: PayloadAction<string>) => {
            state.selectedResultId = action.payload;
        },
        setSearchResults: (state, action: PayloadAction<SearchResultData[]>) => {
            state.searchResultsObjects = action.payload;
        },
        setActiveFilter: (state, action: PayloadAction<string>) => {
            state.activeFilter = action.payload;
        }
    },
});

// reducers
export const { setQuery, setSearchResults, setSelectedResultId, setActiveFilter } = searchResultsSlice.actions;

export const selectQuery = (state: RootState) => state.searchResults.query;
export const selectFilteredSearchResults = (state: RootState) => state.searchResults.searchResultsObjects.filter((element) => ((state.searchResults.activeFilter === "All") || (state.searchResults.activeFilter === element.filterType)));
export const selectSelectedResultId = (state: RootState) => state.searchResults.selectedResultId;
export const selectActiveFilter = (state: RootState) => state.searchResults.activeFilter;
export const selectAllFilters = (state: RootState) => {
    const filterPairs: Record<string, number> = {};

    state.searchResults.searchResultsObjects.forEach(element => {
        const filterType = element.filterType ?? "";
        filterPairs[filterType] = (filterPairs[filterType] || 0) + 1;
    });

    return filterPairs;
}

export default searchResultsSlice.reducer;