import { v4 as uuidv4 } from "uuid";
import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import ConnectQuizPreview from "../../features/previews/connect_quiz/ConnectQuizPreview";
import { GetMigratedProperties } from "../../features/project_migration/InteractionMigrationHelper";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import { Actor } from "../Actor";
import { requiredGlobalQuizProperties } from "../GlobalQuizProperty";
import { InteractionProperty } from "../InteractionProperty";
import { IAnswer } from "../Quiz";
import Take from "../Take";
import { IConnection, IConnectQuiz } from "./ConnectQuiz";
import Interaction, { InteractionType } from "./Interaction";

export const requiredConnectGraphicsProperties: InteractionProperty[] = [
  ...requiredGlobalQuizProperties,
];

export function CreateConnectGraphicsQuiz(keys: string[]): IConnectQuiz {
  return {
    id: uuidv4(),
    name: "Connect Graphics Quiz",
    type: InteractionType.ConnectGraphicsQuiz,
    title: keys[0],
    answersA: [
      { id: uuidv4(), correct: false, text: keys[1] },
      { id: uuidv4(), correct: false, text: keys[2] },
    ],
    answersB: [
      { id: uuidv4(), correct: false, text: keys[3] },
      { id: uuidv4(), correct: false, text: keys[4] },
    ],
    connections: [],
    properties: requiredConnectGraphicsProperties,
  };
}

export function CreateConnectGraphicsQuizCopy(
  titleKey: string,
  answersA: IAnswer[],
  answersB: IAnswer[],
  connections: IConnection[],
  properties: InteractionProperty[] | undefined
): IConnectQuiz {
  return {
    id: uuidv4(),
    name: "Connect Graphics Quiz",
    type: InteractionType.ConnectGraphicsQuiz,
    title: titleKey,
    answersA: answersA,
    answersB: answersB,
    connections: connections,
    properties: properties,
  };
}

export function MigrateConnectGraphicsQuiz(
  from: number,
  to: number,
  interaction: IConnectQuiz
): IConnectQuiz {
  if (interaction.properties === undefined)
    return { ...interaction, properties: requiredConnectGraphicsProperties };

  var newProperties: InteractionProperty[] = GetMigratedProperties(
    requiredConnectGraphicsProperties,
    interaction.properties
  );
  console.log(
    "[ConnectGraphicsQuiz] migrated " +
    (newProperties.length - interaction.properties.length) +
    " properties"
  );

  return { ...interaction, properties: newProperties };
}

export const connectGraphicsQuizInteraction = (): InteractionFactoryEntity => {
  const component = <ConnectQuizPreview displayGraphics={true} />;

  const create = (getKeys: (amount: number) => string[]): IConnectQuiz => {
    const keys = getKeys(5);

    return {
      id: uuidv4(),
      name: "Connect Graphics Quiz",
      type: InteractionType.ConnectGraphicsQuiz,
      title: keys[0],
      answersA: [
        { id: uuidv4(), correct: false, text: keys[1], assetId: "" },
        { id: uuidv4(), correct: false, text: keys[2], assetId: "" },
      ],
      answersB: [
        { id: uuidv4(), correct: false, text: keys[3], assetId: "" },
        { id: uuidv4(), correct: false, text: keys[4], assetId: "" },
      ],
      connections: [],
      properties: requiredConnectGraphicsProperties,
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IConnectQuiz => {
    const connectData = interaction as IConnectQuiz; // need to cast

    if (interaction.properties === undefined)
      return { ...connectData, properties: requiredConnectGraphicsProperties };

    var newProperties: InteractionProperty[] = GetMigratedProperties(
      requiredConnectGraphicsProperties,
      interaction.properties
    );
    console.log(
      "[ConnectGraphicsQuiz] migrated " +
      (newProperties.length - interaction.properties.length) +
      " properties"
    );

    return { ...connectData, properties: newProperties };
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): IConnectQuiz => {
    const formQuiz = interaction as IConnectQuiz;

    const newKeys = getKeys(
      formQuiz.answersA.length + formQuiz.answersB.length + 1
    );

    replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);
    return {
      id: uuidv4(),
      name: interaction.name,
      type: InteractionType.ConnectGraphicsQuiz,
      title: newKeys[newKeys.length - 1],
      answersA: getAnswerArray(formQuiz.answersA, newKeys),
      answersB: getAnswerArray(
        formQuiz.answersB,
        newKeys.slice(formQuiz.answersA.length)
      ),
      connections: formQuiz.connections,
      properties: formQuiz.properties,
    };
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as IConnectQuiz;
    const warnings: string[] = [];

    ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");

    // validate all answers A
    for (let i = 0; i < converted.answersA.length; i++) {
      const tempAnswer = converted.answersA[i];
      ValidationHelper.ValidateKey(
        tempAnswer.text,
        getValue,
        warnings,
        `Answer A ${i + 1}`
      );
    }

    // validate all answers B
    for (let i = 0; i < converted.answersB.length; i++) {
      const tempAnswer = converted.answersB[i];
      ValidationHelper.ValidateAssetId(
        tempAnswer.assetId ?? "",
        warnings,
        `Answer B ${i + 1} asset`
      );
    }
    // validate we have at least one connection
    if (converted.connections.length === 0)
      warnings.push("Quiz must at least have one connection");

    return warnings;
  };

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {

    const converted = interaction as IConnectQuiz;
    const searchableStrings: string[] = [
      converted.name,
      converted.title,
      ...converted.answersA.map(item => item.text),
      ...converted.answersB.map(item => item.text)];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType,
    );
  };

  const filterType: string = "ConnectGraphics Quiz"

  return {
    View: component,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
