import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import StatementQuizPreview from "../../features/previews/statement_quiz/StatementQuizPreview";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { Actor } from "../Actor";
import { IAnswer } from "../Quiz";
import Take from "../Take";
import Interaction, { InteractionType } from "./Interaction";

export interface IStatementQuiz extends Interaction {
  title: string;
  answers: string[];
  statements: string[];
  correctAnswers: number[];
}

export const statementQuizInteraction = (): InteractionFactoryEntity => {
  const component = <StatementQuizPreview />;

  const create = (getKeys: (amount: number) => string[]): IStatementQuiz => {
    const keys = getKeys(4);

    return {
      id: GetNewGuid(),
      name: "Statement Quiz",
      type: InteractionType.StatementQuiz,
      title: keys[0],
      answers: [keys[1], keys[2]],
      statements: [keys[3]],
      correctAnswers: [-1],
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IStatementQuiz => {
    return interaction as IStatementQuiz;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): IStatementQuiz => {
    return create(getKeys);
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as IStatementQuiz;
    const warnings: string[] = [];

    // validate title
    ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");

    // check if statements are not empty
    converted.statements.forEach((statement, index) => {
      ValidationHelper.ValidateKey(
        statement,
        getValue,
        warnings,
        "Statement " + (index + 1)
      );
    });
    // check if answers are not empty
    converted.answers.forEach((answer, index) => {
      ValidationHelper.ValidateKey(
        answer,
        getValue,
        warnings,
        "Answer " + (index + 1)
      );
    });
    // check if correct answers > -1
    converted.correctAnswers.forEach((correctAnswer, index) => {
      if (correctAnswer < 0) {
        warnings.push(
          "Correct answer not selected for statement " + (index + 1)
        );
      }
    });

    return warnings;
  };

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {

    const converted = interaction as IStatementQuiz;
    const searchableStrings: string[] = [
      interaction.name,
      converted.title,
      ...converted.statements,
      ...converted.answers];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType,
    );
  };

  const filterType: string = "Statement Quiz"

  return {
    View: component,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
