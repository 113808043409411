import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import DetachPreview from "../../features/previews/detach/DetachPreview";
import { GetNewGuid } from "../../helpers/GuidHelper";
import Interaction, { InteractionType } from "./Interaction";

export interface IDetach extends Interaction {
    propGuid: string;
    playerGuid: string;
}

export const detachInteraction = (): InteractionFactoryEntity => {
    const component = <DetachPreview />;

    const create = (getKeys: (amount: number) => string[]): IDetach => {
        return {
            id: GetNewGuid(),
            name: "Detach",
            type: InteractionType.Detach,
            propGuid: "",
            playerGuid: ""
        }
    }

    const migrate = (from: number, to: number, interaction: Interaction): IDetach => {
        return interaction as IDetach;
    };

    const copy = (interaction: Interaction, getKeys: (amount: number) => string[], replaceKey: (from: string, to: string) => void): IDetach => {
        return create(getKeys);
    }

    const filter = (
        interaction: Interaction,
        takeName: string,
        getMatchingResults: (
            searchableStrings: string[],
            takeName: string,
            interaction: Interaction,
            filterType: string
        ) => SearchResultData[]
    ): SearchResultData[] => {

        const searchableStrings: string[] = [interaction.name];

        return getMatchingResults(
            searchableStrings,
            takeName,
            interaction,
            filterType,
        );
    };

    const filterType: string = "Detach";

    return {
        View: component,
        FilterType: filterType,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Filter: filter,
    };
}