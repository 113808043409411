import { v4 as uuidv4 } from "uuid";
import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import LisaMessagePreview from "../../features/previews/LisaMessagePreview";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import Interaction, { InteractionType } from "./Interaction";

export interface LisaMessage extends Interaction {
    message: string;
}

export function CreateLisaMessage(messageKey: string): LisaMessage {
    return {
        id: uuidv4(),
        name: "Lisa Message",
        type: InteractionType.LisaMessage,
        message: messageKey,
    };
}

export function MigrateLisaMessage(from: number, to: number, interaction: LisaMessage): LisaMessage {
    return interaction;
}

export const lisaMessageInteraction = (): InteractionFactoryEntity => {
    const component = <LisaMessagePreview />;

    const create = (getKeys: (amount: number) => string[]): LisaMessage => {
        const keys = getKeys(1);

        return {
            id: uuidv4(),
            name: "Lisa Message",
            type: InteractionType.LisaMessage,
            message: keys[0],
        };
    };

    const migrate = (from: number, to: number, interaction: Interaction): LisaMessage => {
        return interaction as LisaMessage;
    };

    const copy = (interaction: Interaction, getKeys: (amount: number) => string[], replaceKey: (from: string, to: string) => void): LisaMessage => {
        const key = getKeys(1)[0];
        replaceKey((interaction as LisaMessage).message, key);
        return {
            id: uuidv4(),
            name: "Lisa Message",
            type: InteractionType.LisaMessage,
            message: key,
        }
    };

    const validate = (interaction: Interaction, getValue: (key: string) => string): string[] => {
        const lm = interaction as LisaMessage;
        const warnings: string[] = [];

        ValidationHelper.ValidateKey(lm.message, getValue, warnings, "Message");

        return warnings;
    }

    const filter = (
        interaction: Interaction,
        takeName: string,
        getMatchingResults: (
            searchableStrings: string[],
            takeName: string,
            interaction: Interaction,
            filterType: string
        ) => SearchResultData[]
    ): SearchResultData[] => {

        const converted = interaction as LisaMessage;
        const searchableStrings: string[] = [
            interaction.name,
            converted.message];

        return getMatchingResults(
            searchableStrings,
            takeName,
            interaction,
            filterType,
        );
    };


    const filterType: string = "Lisa Message";

    return {
        View: component,
        FilterType: filterType,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Validate: validate,
        Filter: filter,
    };
};


