import { CloseOutlined } from "@mui/icons-material";

export interface PiFilterLabelItem {
  text: string;
  onClick: () => void;
}

export default function PiFilterLabel(item: PiFilterLabelItem) {
  const { text, onClick } = item;

  return (
    <div
      aria-label="pi-filterlabel"
      className="text-creator-text-sub font-poppins font-medium leading-[18px] text-[13px] border-[2px] border-creator-primary-faded inline-flex items-center rounded-5 pt-[3px] pb-[3px] pl-[10px] pr-[7px] max-w-full"
    >
      <span className="flex-grow overflow-hidden text-ellipsis whitespace-nowrap">
        {text}
      </span>
      <button
        className="ml-[8px] text-[15px] flex-shrink-0 cursor-pointer hover:text-creator-text-soft"
        onClick={onClick}
      >
        <CloseOutlined fontSize="inherit" />
      </button>
    </div>
  );
}
