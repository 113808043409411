import { v4 as uuidv4 } from "uuid";
import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import AmbientAudioPreview from "../../features/previews/ambient_audio/AmbientAudioPreview";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import { Actor } from "../Actor";
import { IAnswer } from "../Quiz";
import Take from "../Take";
import Interaction, { InteractionType } from "./Interaction";

export interface IAmbientAudio extends Interaction {
  assetId: string;
}

export function CreateAmbientAudio(assetId?: string): IAmbientAudio {
  return {
    id: uuidv4(),
    name: "Ambient Audio",
    type: InteractionType.AmbientAudio,
    assetId: assetId ?? "",
  };
}

export function MigrateAmbientAudio(
  from: number,
  to: number,
  interaction: IAmbientAudio
): IAmbientAudio {
  return interaction;
}

export const ambientAudioInteraction = (): InteractionFactoryEntity => {
  const component = <AmbientAudioPreview />;

  const create = (getKeys: (amount: number) => string[]): IAmbientAudio => {
    return {
      id: uuidv4(),
      name: "Ambient Audio",
      type: InteractionType.AmbientAudio,
      assetId: "",
    };
  };
  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IAmbientAudio => {
    return interaction as IAmbientAudio;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): IAmbientAudio => {
    return {
      ...create(getKeys),
      assetId: (interaction as IAmbientAudio).assetId,
    };
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as IAmbientAudio;
    const warnings: string[] = [];

    ValidationHelper.ValidateAssetId(converted.assetId, warnings);

    return warnings;
  };

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {

    const searchableStrings: string[] = [interaction.name];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType,
    );
  };

  const filterType: string = "Ambient Audio";

  return {
    View: component,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
