import { useSelector } from "react-redux";
import {
  SearchResultData,
  selectQuery,
} from "../../app/slices/SearchResultsSlice";
import Interaction from "../../models/interactions/Interaction";
import { useTrainingLocalization } from "../trainingLocalization/TrainingLocalizationHooks";
import { StringUtils } from "../utils/StringUtils";

export function useAdvancedSearch() {
  const { getValue } = useTrainingLocalization();
  const query = useSelector(selectQuery);

  const getMatchingResults = (
    searchableStrings: string[],
    takeName: string,
    interaction: Interaction,
    filterType: string
  ): SearchResultData[] => {
    const results: SearchResultData[] = [];

    searchableStrings.forEach((searchableString, index) => {
      const localizedString: string =
        getValue(searchableString) || searchableString;
      const searchTarget = localizedString.toLocaleLowerCase();

      if (StringUtils.IsMatching(searchTarget, query)) {
        results.push({
          objectId: interaction.id + index,
          matchingText: localizedString,
          filterType: filterType,
          interactionId: interaction.id,
          takeName: takeName,
          interactionType: interaction.type,
        });
      }
    });

    return results;
  };

  return { getMatchingResults } as const;
}
