import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import AttachPreview from "../../features/previews/attach/AttachPreview";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { IGrab } from "./Grab";
import Interaction, { InteractionType } from "./Interaction";

export interface IAttach extends Interaction {
    playerGuid: string;
    propGuid: string;
    prefabName: string;
}

export const attachInteraction = (): InteractionFactoryEntity => {
    const component = <AttachPreview />

    const create = (getKeys: (amount: number) => string[]): IAttach => {
        return {
            id: GetNewGuid(),
            name: "Attach",
            type: InteractionType.Attach,
            playerGuid: "",
            propGuid: "",
            prefabName: ""
        }
    }

    const migrate = (from: number, to: number, interaction: Interaction): IAttach => {
        return interaction as IAttach;
    };

    const copy = (interaction: Interaction, getKeys: (amount: number) => string[], replaceKey: (from: string, to: string) => void): IGrab => {
        return create(getKeys);
    }

    const filter = (
        interaction: Interaction,
        takeName: string,
        getMatchingResults: (
            searchableStrings: string[],
            takeName: string,
            interaction: Interaction,
            filterType: string
        ) => SearchResultData[]): SearchResultData[] => {

        const searchableStrings: string[] = [interaction.name];

        return getMatchingResults(
            searchableStrings,
            takeName,
            interaction,
            filterType,
        );
    };

    const filterType: string = "Attach";

    return {
        View: component,
        FilterType: filterType,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Filter: filter,
    };
}