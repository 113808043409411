import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import SlideshowPreview from "../../features/previews/slideshow/SlideshowPreview";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { Actor } from "../Actor";
import { IAnswer } from "../Quiz";
import Take from "../Take";
import Interaction, { InteractionType } from "./Interaction";

export interface ISlideshow extends Interaction {
  title: string;
  assetIds: string[];
}

export const slideshowInteraction = (): InteractionFactoryEntity => {
  const component = <SlideshowPreview />;

  const create = (getKeys: (amount: number) => string[]): ISlideshow => {
    return {
      id: GetNewGuid(),
      title: getKeys(1)[0],
      name: "Slideshow",
      type: InteractionType.Slideshow,
      assetIds: [""],
    };
  };

  const migrate = (from: number, to: number, interaction: Interaction) => {
    return interaction;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): ISlideshow => {
    const converted = interaction as ISlideshow;
    const copiedTitle = getKeys(1)[0];
    replaceKey(converted.title, copiedTitle);

    return {
      id: GetNewGuid(),
      title: copiedTitle,
      name: converted.name,
      type: InteractionType.Slideshow,
      assetIds: converted.assetIds,
      properties: converted.properties,
    };
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as ISlideshow;
    const result: string[] = [];

    ValidationHelper.ValidateKey(converted.title, getValue, result, "Title");

    for (let i = 0; i < converted.assetIds.length; i++) {
      ValidationHelper.ValidateAssetId(
        converted.assetIds[i],
        result,
        `Image ${i + 1} does not have a valid image`
      );
    }

    return result;
  };

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {

    const converted = interaction as ISlideshow;
    const searchableStrings: string[] = [
      interaction.name,
      converted.title];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType,
    );
  };


  const filterType: string = "SlideshowFilter";

  return {
    View: component,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
