import MarkerPreview from "../../features/previews/MarkerPreview";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { IAnswer } from "../Quiz";
import Interaction, { InteractionType, Marker } from "./Interaction";

export default function TeleportMarkerInteraction() {
  const component = <MarkerPreview variant={"teleport"} />;

  const create = (getKeys: (amount: number) => string[]): Marker => {
    return {
      id: GetNewGuid(),
      name: "Marker",
      type: InteractionType.Marker,
      xPos: 0.5,
      yPos: 0.5,
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): Marker => {
    return interaction as Marker;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): Marker => {
    return create(getKeys);
  };

  const filterType: string = "Teleport marker";

  return {
    View: component,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
  };
}
