import { v4 as uuidv4 } from "uuid";
import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import ConnectQuizPreview from "../../features/previews/connect_quiz/ConnectQuizPreview";
import { GetMigratedProperties } from "../../features/project_migration/InteractionMigrationHelper";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import { Actor } from "../Actor";
import { requiredGlobalQuizProperties } from "../GlobalQuizProperty";
import { InteractionProperty } from "../InteractionProperty";
import { IAnswer } from "../Quiz";
import Take from "../Take";
import Interaction, { InteractionType } from "./Interaction";

export interface IConnectQuiz extends Interaction {
    title: string;
    answersA: IAnswer[];
    answersB: IAnswer[];
    connections: IConnection[];
}

export interface IConnection {
    idA: string;
    idB: string;
}

export const requiredConnectProperties: InteractionProperty[] = [...requiredGlobalQuizProperties];

export function CreateConnectQuiz(keys: string[]): IConnectQuiz {
    return {
        id: uuidv4(),
        name: "Connect Quiz",
        type: InteractionType.ConnectQuiz,
        title: keys[0],
        answersA: [
            { id: uuidv4(), correct: false, text: keys[1] },
            { id: uuidv4(), correct: false, text: keys[2] },
        ],
        answersB: [
            { id: uuidv4(), correct: false, text: keys[3] },
            { id: uuidv4(), correct: false, text: keys[4] },
        ],
        connections: [],
        properties: requiredConnectProperties,
    };
}

export function CreateConnectQuizCopy(titleKey: string, answersA: IAnswer[], answersB: IAnswer[], connections: IConnection[], properties: InteractionProperty[] | undefined): IConnectQuiz {
    return {
        id: uuidv4(),
        name: "Connect Quiz",
        type: InteractionType.ConnectQuiz,
        title: titleKey,
        answersA: answersA,
        answersB: answersB,
        connections: connections,
        properties: properties,
    };
}

export function MigrateConnectQuiz(from: number, to: number, interaction: IConnectQuiz): IConnectQuiz {
    if (interaction.properties === undefined) return { ...interaction, properties: requiredConnectProperties };

    var newProperties: InteractionProperty[] = GetMigratedProperties(requiredConnectProperties, interaction.properties);
    console.log("[ConnectQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

    return { ...interaction, properties: newProperties };
}

export const connectQuizInteraction = (): InteractionFactoryEntity => {
    const component = <ConnectQuizPreview />;

    const create = (getKeys: (amount: number) => string[]): IConnectQuiz => {
        const keys = getKeys(5);

        return {
            id: uuidv4(),
            name: "Connect Quiz",
            type: InteractionType.ConnectQuiz,
            title: keys[0],
            answersA: [
                { id: uuidv4(), correct: false, text: keys[1] },
                { id: uuidv4(), correct: false, text: keys[2] },
            ],
            answersB: [
                { id: uuidv4(), correct: false, text: keys[3] },
                { id: uuidv4(), correct: false, text: keys[4] },
            ],
            connections: [],
            properties: requiredConnectProperties,
        };
    };

    const migrate = (from: number, to: number, interaction: Interaction): IConnectQuiz => {
        const quizData = interaction as IConnectQuiz; // need to cast
        if (interaction.properties === undefined) return { ...quizData, properties: requiredConnectProperties };

        var newProperties: InteractionProperty[] = GetMigratedProperties(requiredConnectProperties, interaction.properties);
        console.log("[ConnectQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

        return { ...quizData, properties: newProperties };
    };

    const copy = (interaction: Interaction,
        getKeys: (amount: number) => string[],
        replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IConnectQuiz => {
        const formQuiz = interaction as IConnectQuiz;

        const newKeys = getKeys(formQuiz.answersA.length + formQuiz.answersB.length + 1);

        replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);
        return {
            id: uuidv4(),
            name: interaction.name,
            type: InteractionType.ConnectQuiz,
            title: newKeys[newKeys.length - 1],
            answersA: getAnswerArray(formQuiz.answersA, newKeys),
            answersB: getAnswerArray(formQuiz.answersB, newKeys.slice(formQuiz.answersA.length)),
            connections: formQuiz.connections,
            properties: formQuiz.properties,
        };
    }

    const validate = (interaction: Interaction, getValue: (key: string) => string, take: Take, actorPresets: Actor[]): string[] => {
        const converted = interaction as IConnectQuiz;
        const warnings: string[] = [];

        ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");

        // validate all answers A 
        for (let i = 0; i < converted.answersA.length; i++) {
            const tempAnswer = converted.answersA[i];
            ValidationHelper.ValidateKey(tempAnswer.text, getValue, warnings, `Answer A ${i + 1}`);
        }
        // validate all answers B
        for (let i = 0; i < converted.answersB.length; i++) {
            const tempAnswer = converted.answersB[i];
            ValidationHelper.ValidateKey(tempAnswer.text, getValue, warnings, `Answer B ${i + 1}`);
        }
        // validate we have at least one connection
        if (converted.connections.length === 0)
            warnings.push("Quiz must at least have one connection");

        return warnings;
    }

    const filter = (
        interaction: Interaction,
        takeName: string,
        getMatchingResults: (
            searchableStrings: string[],
            takeName: string,
            interaction: Interaction,
            filterType: string
        ) => SearchResultData[]
    ): SearchResultData[] => {

        const converted = interaction as IConnectQuiz;
        const searchableStrings: string[] = [
            converted.name,
            converted.title,
            ...converted.answersA.map(item => item.text),
            ...converted.answersB.map(item => item.text)];

        return getMatchingResults(
            searchableStrings,
            takeName,
            interaction,
            filterType,
        );
    };

    const filterType: string = "Connect Quiz";

    return {
        View: component,
        FilterType: filterType,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Validate: validate,
        Filter: filter,
    };
};

