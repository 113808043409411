import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import ImageButtonQuizPreview from "../../features/previews/image_button_quiz/ImageButtonQuizPreview";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { Actor } from "../Actor";
import { IFormQuiz } from "../Quiz";
import Take from "../Take";
import Interaction, { InteractionType } from "./Interaction";

export const ImageButtonQuizInteraction = (): InteractionFactoryEntity => {
  const view = <ImageButtonQuizPreview />;

  const create = (getKeys: (amount: number) => string[]): IFormQuiz => {
    const keys = getKeys(1);

    return {
      id: GetNewGuid(),
      name: "Image Button Quiz",
      type: InteractionType.ImageButtonQuiz,
      title: keys[0],
      answers: [
        {
          id: GetNewGuid(),
          text: "",
          assetId: "",
          correct: false,
        },
        {
          id: GetNewGuid(),
          text: "",
          assetId: "",
          correct: false,
        },
      ],
      properties: [],
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IFormQuiz => {
    return interaction as IFormQuiz;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void
  ): IFormQuiz => {
    return create(getKeys);
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as IFormQuiz;
    const result: string[] = [];

    // validate title
    ValidationHelper.ValidateKey(converted.title, getValue, result, "Title");

    // validate answers + correct answer
    var hasCorrectAnswer = false;
    for (let i = 0; i < converted.answers.length; i++) {
      // asset id
      ValidationHelper.ValidateAssetId(
        converted.answers[i].assetId ?? "",
        result,
        `Image ${i + 1}`
      );

      // quiz has at least one correct answer
      if (converted.answers[i].correct) hasCorrectAnswer = true;
    }

    if (!hasCorrectAnswer)
      result.push("Quiz must have at least one correct answer");

    return result;
  };

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {

    const converted = interaction as IFormQuiz;
    const searchableStrings: string[] = [
      interaction.name,
      converted.title];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType,
    );
  };

  const filterType: string = "Image Button Quiz";

  return {
    View: view,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
