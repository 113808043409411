import { v4 as uuidv4 } from "uuid";
import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import ClickRegionOrderQuizPreview from "../../features/previews/click_region_order_quiz/ClickRegionOrderQuizPreview";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import { Actor } from "../Actor";
import { IAnswer } from "../Quiz";
import Take from "../Take";
import { CreateCustomClickRegion, IClickRegion } from "./ClickRegion";
import Interaction, { InteractionType } from "./Interaction";

export interface IClickRegionOrderQuiz extends Interaction {
  title: string;
  clickregions: IClickRegion[];
}

export function CreateClickRegionOrderQuiz(key: string): IClickRegionOrderQuiz {
  return {
    id: uuidv4(),
    name: "Click Region Order Quiz",
    type: InteractionType.ClickRegionOrderQuiz,
    title: key,
    clickregions: [
      CreateCustomClickRegion(0.4, 0.5, 0.1, 0.1),
      CreateCustomClickRegion(0.6, 0.5, 0.1, 0.1),
    ],
  };
}

export function MigrateClickRegionOrderQuiz(
  from: number,
  to: number,
  interaction: IClickRegionOrderQuiz
): IClickRegionOrderQuiz {
  return interaction;
}

export const clickRegionOrderQuizInteraction = (): InteractionFactoryEntity => {
  const component = <ClickRegionOrderQuizPreview />;
  const create = (
    getKeys: (amount: number) => string[]
  ): IClickRegionOrderQuiz => {
    const keys = getKeys(1);
    return {
      id: uuidv4(),
      name: "Click Region Order Quiz",
      type: InteractionType.ClickRegionOrderQuiz,
      title: keys[0],
      clickregions: [
        CreateCustomClickRegion(0.4, 0.5, 0.1, 0.1),
        CreateCustomClickRegion(0.6, 0.5, 0.1, 0.1),
      ],
    };
  };
  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IClickRegionOrderQuiz => {
    return interaction as IClickRegionOrderQuiz;
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as IClickRegionOrderQuiz;
    const warnings: string[] = [];

    // Title
    ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");

    return warnings;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): IClickRegionOrderQuiz => {
    return create(getKeys);
  };

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {

    const converted = interaction as IClickRegionOrderQuiz;
    const searchableStrings: string[] = [
      interaction.name,
      converted.title];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType,
    );
  };


  const filterType: string = "ClickRegion Order Quiz";

  return {
    View: component,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
