import { v4 as uuidv4 } from "uuid";
import { IAnswer, IFormQuiz } from "../Quiz";
import Interaction, { InteractionType } from "./Interaction";

import {
  InteractionFactoryEntity
} from "../../features/interaction_factory/InteractionFactory";

import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import DialogQuizPreview from "../../features/previews/dialog_quiz/DialogQuizPreview";
import { GetMigratedProperties } from "../../features/project_migration/InteractionMigrationHelper";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import { Actor } from "../Actor";
import {
  InteractionProperty,
  InteractionPropertyType,
} from "../InteractionProperty";
import Take from "../Take";

const rightSide: InteractionProperty = {
  id: "rightSide",
  name: "rightSide",
  locId: "rightSide",
  type: InteractionPropertyType.boolean,
  value: false,
};

export const requiredDialogquizProps: InteractionProperty[] = [rightSide];

export interface IDialogQuiz extends IFormQuiz {
  playerGuid: string;
}

export const dialogQuizInteraction = (): InteractionFactoryEntity => {
  const component = <DialogQuizPreview />;

  const create = (getKeys: (amount: number) => string[]): IDialogQuiz => {
    const keys = getKeys(3);
    return {
      id: uuidv4(),
      name: "Dialog Quiz",
      type: InteractionType.DialogQuiz,
      title: keys[0],
      answers: [
        { id: uuidv4(), correct: true, text: keys[1] },
        { id: uuidv4(), correct: false, text: keys[2] },
      ],
      playerGuid: "player",
      properties: requiredDialogquizProps,
    };
  };
  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IDialogQuiz => {
    const quizData = interaction as IDialogQuiz;

    if (quizData.properties === undefined)
      return { ...quizData, properties: requiredDialogquizProps };

    var newProperties: InteractionProperty[] = GetMigratedProperties(
      requiredDialogquizProps,
      quizData.properties
    );

    console.log(
      "[MultiSelectQuiz] migrated " +
      (newProperties.length - quizData.properties.length) +
      " properties"
    );

    return interaction as IDialogQuiz;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): IDialogQuiz => {
    const formQuiz = interaction as IDialogQuiz;
    const newKeys = getKeys(formQuiz.answers.length + 1);
    replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);
    return {
      id: uuidv4(),
      name: interaction.name,
      type: InteractionType.DialogQuiz,
      title: newKeys[newKeys.length - 1],
      answers: getAnswerArray(formQuiz.answers, newKeys),
      playerGuid: formQuiz.playerGuid,
      properties: formQuiz.properties,
    };
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as IDialogQuiz;
    const warnings: string[] = [];

    ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");

    let hasCorrectAnswer = false;

    // validate all answers + validate we have at least one answer
    for (let i = 0; i < converted.answers.length; i++) {
      const tempAnswer = converted.answers[i];
      ValidationHelper.ValidateKey(
        tempAnswer.text,
        getValue,
        warnings,
        `Answer ${i + 1}`
      );
      ValidationHelper.ValidateKey(
        tempAnswer.text,
        getValue,
        warnings,
        `Answer ${i + 1}`
      );
      if (tempAnswer.correct) hasCorrectAnswer = true;
    }

    if (!hasCorrectAnswer) warnings.push("No correct answer selected");

    return warnings;
  };

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {

    const converted = interaction as IDialogQuiz;
    const searchableStrings: string[] = [
      converted.name,
      converted.title,
      ...converted.answers.map(item => item.text)];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType,
    );
  };

  const filterType: string = "Dialog Quiz";

  return {
    View: component,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
