import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectedTab, selectSelectedTab } from "../../app/slices/GlobalSlice";
import { selectActiveFilter, selectQuery, setActiveFilter, setQuery, setSearchResults } from "../../app/slices/SearchResultsSlice";
import { selectIsPlaying } from "../../app/slices/TrainingSlice";
import PiFilterDropdown from "../../components/PiFilterDropDown";
import PiFilterLabel from "../../components/PiFilterLabel";
import PiInputfield from "../../components/PiInputField";
import AdvancedSearchColumnHeader from "./AdvancedSearchColumnHeader";
import AdvancedSearchList from "./AdvancedSearchList";

export default function AdvancedSearchColumn() {
  const timelineSelected = useSelector(selectSelectedTab) === SelectedTab.Timeline;
  const isPlaying = useSelector(selectIsPlaying);
  const query = useSelector(selectQuery);
  const activeFilter = useSelector(selectActiveFilter);

  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const onClick = () => {
    dispatch(setQuery(""));
    dispatch(setActiveFilter("All"));
    setIsSelected(!isSelected);
  };

  const onChange = (value: any) => {
    dispatch(setQuery(value));
    if (value === "" || value === undefined) {
      dispatch(setActiveFilter("All"))
      dispatch(setSearchResults([]))
    };
  };

  const column = (
    <div aria-label="advanced-search-column" className={`h-full bg-white`}>
      <AdvancedSearchColumnHeader
        isSelected={isSelected}
        onClickCallback={onClick}
      />
      {isSelected ? (
        <div className="flex items-end max-w-full justify-evenly gap-[10px] pt-[12px] pl-[15px] pr-[15px]">
          <PiInputfield
            label=""
            placeholder="Search"
            showSearchIcon={true}
            onChange={onChange}
            showCrossIcon={true}
          />
          <PiFilterDropdown />
        </div>
      ) : (
        ""
      )}
      <div className="w-full h-full overflow-y-auto pit-creator-scrollbar">
        <div className="flex flex-row max-w-[292px] flex-wrap gap-[10px] px-[14px] py-[12px]">
          {activeFilter !== "All" && PiFilterLabel({ text: activeFilter, onClick: () => dispatch(setActiveFilter("All")) })}
        </div>
        <div className="flex flex-col bg-transparent ">
          {isSelected && query ? (<AdvancedSearchList />) : ("")}
        </div>
      </div>
    </div>
  );

  const isOpen = isSelected;
  const shouldRenderColumn = !isPlaying && timelineSelected && isOpen;
  const shouldRenderHeader = !isPlaying && timelineSelected && !isOpen;

  return (
    <>
      {shouldRenderColumn && column}
      {shouldRenderHeader && (
        <div className="absolute z-50 top-0 left-[292px]  bg-creator-error">
          <AdvancedSearchColumnHeader
            isSelected={isSelected}
            onClickCallback={onClick}
          />
        </div>
      )}
    </>
  );
}
