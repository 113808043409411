import { ReactElement } from "react";
import {
    SearchResultData
} from "../../app/slices/SearchResultsSlice";
import { Actor } from "../../models/Actor";
import Interaction from "../../models/interactions/Interaction";
import { IAnswer } from "../../models/Quiz";
import Take from "../../models/Take";

export type InteractionFactoryEntity = {
    View: ReactElement;
    FilterType: string;
    Create: (getKeys: (amount: number) => string[]) => Interaction;
    Migrate: (from: number, to: number, interaction: Interaction) => Interaction;
    Copy?: (
        interaction: Interaction,
        getKeys: (amount: number) => string[],
        replaceKey: (from: string, to: string) => void,
        getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
    ) => Interaction;
    Validate?: (
        interaction: Interaction,
        getValue: (key: string) => string,
        currentTake: Take,
        actorPresets: Actor[]
    ) => string[];
    Filter?: (
        interaction: Interaction,
        takeName: string,
        getMatchingResults: (
            searchableStrings: string[],
            takeName: string,
            interaction: Interaction,
            filterType: string
        ) => SearchResultData[],
        getValue: (key: string) => string
    ) => SearchResultData[];
};

type InteractionMap = {
    [key: string]: InteractionFactoryEntity;
};

const interactionMap: InteractionMap = {};

// Function to register a new interaction
export const registerInteraction = (
    interactionType: string,
    interaction: InteractionFactoryEntity
): void => {
    interactionMap[interactionType] = interaction;
};

// Factory function to retrieve an interaction
export const getInteractionFromFactory = (
    interactionType: string
): InteractionFactoryEntity => {
    return interactionMap[interactionType];
};
