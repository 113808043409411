import { v4 as uuidv4 } from "uuid";
import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import {
  InteractionFactoryEntity,
  registerInteraction
} from "../../features/interaction_factory/InteractionFactory";
import MarkerPreview from "../../features/previews/MarkerPreview";
import { IAnswer } from "../Quiz";
import Interaction, { InteractionType, Marker } from "./Interaction";

export function CreateMarker(): Marker {
  return {
    id: uuidv4(),
    name: "Marker",
    type: InteractionType.Marker,
    xPos: 0.5,
    yPos: 0.5,
  };
}

export function MigrateMarker(
  from: number,
  to: number,
  interaction: Marker
): Marker {
  return interaction;
}

export const markerInteraction = (): InteractionFactoryEntity => {
  const component = <MarkerPreview variant={"pin"} />;

  const create = (getKeys: (amount: number) => string[]): Marker => {
    return {
      id: uuidv4(),
      name: "Marker",
      type: InteractionType.Marker,
      xPos: 0.5,
      yPos: 0.5,
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): Marker => {
    return interaction as Marker;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): Marker => {
    return create(getKeys);
  };

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {

    const searchableStrings: string[] = [interaction.name];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType,
    );
  };

  const filterType: string = "Marker";

  return {
    View: component,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Filter: filter
  };
};

registerInteraction(InteractionType.Marker, markerInteraction());
