import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import ShowUIPropPreview from "../../features/previews/show_ui_prop/ShowUIPropPreview";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { Actor } from "../Actor";
import Take from "../Take";
import Interaction, { InteractionType } from "./Interaction";

export interface IShowUIProp extends Interaction {
  propGuid: string;
  prefabName: string;
}

export const ShowUIPropInteraction = (): InteractionFactoryEntity => {
  const view = <ShowUIPropPreview />;

  const create = (getKeys: (amount: number) => string[]): IShowUIProp => {
    return {
      id: GetNewGuid(),
      name: "Show UI Prop",
      type: InteractionType.ShowUIProp,
      propGuid: "",
      prefabName: "",
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IShowUIProp => {
    return interaction as IShowUIProp;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void
  ): IShowUIProp => {
    return create(getKeys);
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as IShowUIProp;
    const warnings: string[] = [];

    if (!converted.propGuid) {
      warnings.push("No prop selected.");
    }

    return warnings;
  };

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {

    const converted = interaction as IShowUIProp;
    const searchableStrings: string[] = [
      interaction.name,
      converted.prefabName];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType,
    );
  };

  const filterType: string = "ShowUIProp";

  return {
    View: view,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
