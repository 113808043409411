import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SearchResultData,
  selectActiveFilter,
  selectQuery,
  selectSelectedResultId,
  setSearchResults,
} from "../../app/slices/SearchResultsSlice";
import { selectLocalizedEntries } from "../../app/slices/TrainingLocalizationSlice";
import { selectTakes } from "../../app/slices/TrainingSlice";
import Take from "../../models/Take";
import { getInteractionFromFactory } from "../interaction_factory/InteractionFactory";
import { useTrainingLocalization } from "../trainingLocalization/TrainingLocalizationHooks";
import { StringUtils } from "../utils/StringUtils";
import { useAdvancedSearch } from "./AdvancedSearch.hook";

export const useUpdateSearchResults = () => {
  const query = useSelector(selectQuery);
  const selectedResultId = useSelector(selectSelectedResultId);
  const selectedActiveFilter = useSelector(selectActiveFilter);
  const takes: Take[] = useSelector(selectTakes);
  const localizations = useSelector(selectLocalizedEntries);
  const dispatch = useDispatch();
  const { getValue } = useTrainingLocalization();

  const { getMatchingResults } = useAdvancedSearch();

  useEffect(() => {
    const results: SearchResultData[] = [];

    takes.forEach((take) => {
      if (StringUtils.IsMatching(take.name, query)) {
        results.push({
          objectId: take.id,
          matchingText: take.name,
          filterType: "Take",
        });
      }

      take.interactions.forEach((interaction) => {
        const interactionFactory = getInteractionFromFactory(interaction.type);

        if (
          interactionFactory &&
          typeof interactionFactory.Filter === "function"
        ) {
          let matchingSearchResults = interactionFactory.Filter(
            interaction,
            take.name,
            getMatchingResults,
            getValue
          );

          results.push(...matchingSearchResults);
        }
      });
    });

    dispatch(setSearchResults(results));
  }, [query, takes, localizations, selectedResultId, selectedActiveFilter]);
};
