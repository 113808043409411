import { MovieOutlined } from "@mui/icons-material";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedResultId, setSelectedResultId } from "../../app/slices/SearchResultsSlice";
import {
  selectActors,
  setSelectedInteraction,
  setSelectedTake
} from "../../app/slices/TrainingSlice";
import { InteractionType } from "../../models/interactions/Interaction";
import { ActorHelper } from "../actor_presets/ActorHelper";
import { AllInteractions } from "../interaction_picker/InteractionDefinitions";
import { useInteractionPickerIcons } from "../interaction_picker/InteractionPickerIconHooks";
import { StringUtils } from "../utils/StringUtils";

export interface AdvancedSearchResulFieldProps {
  objectId: string;
  matchingText: string;
  query: string;
  interactionId?: string;
  takeName?: string;
  interactionType?: InteractionType | null;
  profilePicture?: string;
}

export default function AdvancedSearchResultField(
  props: AdvancedSearchResulFieldProps
) {
  const {
    matchingText,
    query,
    objectId,
    interactionType,
    takeName,
    profilePicture,
    interactionId
  } = { ...props };

  const dispatch = useDispatch();
  const [getIcon] = useInteractionPickerIcons();
  const [before, queryString, after] = StringUtils.SplitStringAroundQuery(
    matchingText,
    query
  );
  const selectedId = useSelector(selectSelectedResultId);
  const allActors = useSelector(selectActors);

  // Find the interaction definition based on the interaction type
  const interactionDef = interactionType
    ? AllInteractions.find((def) => def.type === interactionType)
    : null;

  const handleClick = () => {
    dispatch(setSelectedResultId(objectId));
    interactionDef
      ? dispatch(setSelectedInteraction(interactionId ?? ""))
      : dispatch(setSelectedTake(objectId));
  };

  const icon = interactionDef ? (
    <div className="text-[22px]">{getIcon(interactionDef)}</div>
  ) : (
    <MovieOutlined fontSize="inherit" color="inherit" />
  );

  const MAX_LENGTH = 20; // Adjust this value based on the allowed character length for the field

  // Ensure that no spaces are inserted during rendering
  const renderResult = (before: string, queryString: string, after: string) => {
    return (
      <>
        <span className="truncate">{before.replace(/ /g, "\u00A0")}</span>
        <span className="bg-creator-primary-washed text-creator-primary whitespace-nowrap">
          {queryString.replace(/ /g, "\u00A0")}
        </span>
        <span className="truncate">{after.replace(/ /g, "\u00A0")}</span>
      </>
    );
  };

  const { truncatedBefore, truncatedAfter } = StringUtils.TruncateAroundQueryString(
    before,
    queryString,
    after,
    MAX_LENGTH
  );

  const view = (
    <button
      className={classNames({
        "box-border relative flex w-full items-center h-[50px] max-w-[292px] pb-[10px] pl-[17px] pt-[10px] pr-[17px] border-b-[1px] border-creator-border hover:bg-creator-primary-washed":
          true,
        "bg-creator-childActive": selectedId === objectId,
      })}
      onClick={handleClick}
    >
      <div className="flex items-center h-full w-full text-[12px] font-normal text-creator-text-sub text-ellipsis">
        <div className="flex-shrink-0 text-[22px]">{icon}</div>
        <div className={`${profilePicture ? "ml-[10px]" : ""} `}>
          {profilePicture ? ActorHelper.GetActorProfilePicture(profilePicture, allActors) : ""}
        </div>
        <div className="flex flex-col items-start ml-2">
          <div className="text-[10px] font-normal">{takeName}</div>
          <div className="flex flex-row w-full ">
            {renderResult(truncatedBefore, queryString, truncatedAfter)}
          </div>
        </div>
      </div>
    </button>
  );

  return <>{view}</>;
}
