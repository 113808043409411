import { DynamicInteraction } from "../../dto/PropMetadata";
import DynamicInteractionPreview from "../../features/dynamic_interactions/DynamicInteractionPreview";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import { GetNewGuid } from "../../helpers/GuidHelper";
import Interaction, { InteractionType } from "./Interaction";

export interface IDynamicInteraction extends Interaction {
    dynamicInteractionProperties: IDynamicInteractionProperty[];
    interactionTag: string;
    source: string;
}

export enum DynamicInteractionPropertyType {
    actorDropdown = "actor_dropdown",
    stringDropdown = "string_dropdown",
    localizedText = "localized_text"
}

export interface IDynamicInteractionProperty {
    id: string;
    name: string;
    description: string;
    type: DynamicInteractionPropertyType;
    requiredTag?: string; // eg. "extinguisher", when interaction requires npc holding extinguisher
    value: string;
    values?: string[]; // for dropdowns !
}

export function CreateDynamicInteraction(dynamicInteraction: DynamicInteraction, sourceInstanceId: string, getArrayOfKeys: (amount: number) => string[]): IDynamicInteraction {
    console.log(dynamicInteraction);

    // request keys for localized text
    const amountOfKeysNeeded = dynamicInteraction.properties.filter(d => d.type === DynamicInteractionPropertyType.localizedText).length;
    const keys = getArrayOfKeys(amountOfKeysNeeded);
    let counter = 0;

    // generate properties
    const dynProps: IDynamicInteractionProperty[] = dynamicInteraction.properties.map((prop) => {
        let result = {
            ...prop,
            value: ""
        } as IDynamicInteractionProperty;

        if (result.type === DynamicInteractionPropertyType.localizedText) {
            result.value = keys[counter];
            counter += 1;
        }
        return result;
    });

    console.log("DYNAMIC PROPS");
    console.log(dynProps);

    return {
        id: GetNewGuid(),
        name: dynamicInteraction.title,
        type: InteractionType.DynamicInteraction,
        interactionTag: dynamicInteraction.interactionTag,
        source: sourceInstanceId,
        dynamicInteractionProperties: dynProps,
        properties: [],
    }
}

export const dynamicInteraction = (): InteractionFactoryEntity => {
    const view = <DynamicInteractionPreview />;
    const create = (getKeys: (amount: number) => string[]): IDynamicInteraction => {
        return {
            id: GetNewGuid(),
            name: "Should not be used",
            type: InteractionType.DynamicInteraction,
            interactionTag: "",
            source: "",
            dynamicInteractionProperties: [],
            properties: [],
        };
    }
    const migrate = (from: number, to: number, interaction: Interaction): IDynamicInteraction => {
        return interaction as IDynamicInteraction;
    }

    const filterType = "Dynamic Interaction";

    return { View: view, FilterType: filterType, Create: create, Migrate: migrate };
}