export class StringUtils {
  static SplitStringAroundQuery(mainString: string, queryString: string): [string, string, string] {
    const queryIndex = mainString.toLowerCase().indexOf(queryString.toLowerCase());

    if (queryIndex === -1) {
      return [mainString, "", ""]; // Query not found
    }

    const beforeQuery = mainString.substring(0, queryIndex);
    const matchingQuery = mainString.substring(queryIndex, queryIndex + queryString.length);
    const afterQuery = mainString.substring(queryIndex + queryString.length);

    return [beforeQuery, matchingQuery, afterQuery];
  }

  static TruncateAroundQueryString = (before: string, queryString: string, after: string, maxLength: number) => {
    const queryStringLength = queryString.length;
    const allowedLength = maxLength - queryStringLength;

    // Check if no truncation is needed
    if (before.length + after.length <= allowedLength) {
      return { truncatedBefore: before, truncatedAfter: after };
    }

    const halfAllowed = Math.floor(allowedLength / 2);
    let truncatedBefore = before.length > halfAllowed ? "..." + before.slice(before.length - halfAllowed) : before;
    let truncatedAfter = after.length > halfAllowed ? after.slice(0, halfAllowed) + "..." : after;

    return { truncatedBefore: truncatedBefore, truncatedAfter: truncatedAfter };
  };

  static IsMatching(text: string, query: string): string | boolean {
    return text && text !== "" && text.toLocaleLowerCase().includes(query.toLocaleLowerCase());
  }
}
