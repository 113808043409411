import { Check, TuneOutlined } from "@mui/icons-material";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveFilter, selectAllFilters, selectFilteredSearchResults, setActiveFilter } from "../app/slices/SearchResultsSlice";

export interface PiFilterDropDownListItem {
  text: string;
  count: number;
}

export default function PiFilterDropdown() {
  const searchResults = useSelector(selectFilteredSearchResults);
  const allFilters = useSelector(selectAllFilters);
  const activeFilter: string = useSelector(selectActiveFilter);
  const dispatch = useDispatch();
  const [dropDownItems, setDropDownItems] = useState<PiFilterDropDownListItem[]>([]);

  useEffect(() => {
    setDropDownItems(createDropDownArray());
  }, [searchResults]);

  const createDropDownArray = () => {
    let items: PiFilterDropDownListItem[] = [{ text: "All", count: 0 }];
    let currentFilterFound: boolean = false;

    Object.entries(allFilters).forEach(([key, value]) => {
      if (key === activeFilter) { currentFilterFound = true; }
      items[0].count += value;
      items.push({ text: key, count: value });
    });

    if (!activeFilter || !currentFilterFound) {
      dispatch(setActiveFilter("All"));
    }

    return items
  }

  const filterItems =
    dropDownItems.map(
      (listItem: PiFilterDropDownListItem) => {
        return (
          <DropdownMenu.RadioItem
            className="relative flex select-none items-center justify-around rounded-[3px] pl-[10px] pr-[5px] text-[14px] leading-none text-creator-text-sub py-[8px] gap-[10px] font-poppins font-medium cursor-pointer focus:outline-none hover:bg-creator-primary-washed overflow-y-auto pit-creator-scrollbar"
            key={listItem.text}
            value={listItem.text}
          >
            <DropdownMenu.ItemIndicator className="absolute left-0 inline-flex w-[20px] items-center justify-center">
              <Check className="h-4 min-w-[20px] max-w-[20px] " />
            </DropdownMenu.ItemIndicator>
            <div className="w-[135px] h-[20px] pl-[10px] leading-5 [overflow:hidden] [whiteSpace:nowrap] [textOverflow:ellipsis]">
              {listItem.text}
            </div>
            <div className="flex min-w-[18px] h-[18px] bg-creator-primary-disabled rounded-full justify-center px-[5px] items-center box">
              {listItem.count}
            </div>
          </DropdownMenu.RadioItem>
        );
      });

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button
          className="min-w-[40px] max-w-[40px] text-white bg-creator-primary rounded-circle aspect-square hover:bg-creator-primary-hover focus:outline-none"
          aria-label="filter-list-trigger"
        >
          <TuneOutlined />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="z-50 min-w-[226px] bg-white rounded-md py-[10px] px-[8px] shadow-base border-[1px] border-interaction-border max-h-[500px] mt-[-14px] overflow-y-auto pit-creator-scrollbar"
          align="end"
          alignOffset={-4}
        >
          < DropdownMenu.RadioGroup value={activeFilter} onValueChange={(value) => dispatch(setActiveFilter(value))} >
            {filterItems}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
